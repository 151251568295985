<template>
     <section class="page_title  pdt_80  pdb_40">
               <div class="container">
                  <div class="row">
                     <!---------row----------->
                     <div class="col-lg-12 col-md-12 text-center">
                        <!---------col----------->
                        <div class="content_box">
                           <h6>What is Coronavirus </h6>
                           <h1>About the COVID-19</h1>
                           <ul class="bread_crumb text-center">
                              <li class="bread_crumb-item"><a href="#">Home</a></li>
                              <li class="bread_crumb-item"><a href="#">About</a></li>
                              <li class="bread_crumb-item active"> About COVID-19</li>
                           </ul>
                        </div>
                        <!---------col-end---------->
                     </div>
                     <!----------row-end---------->
                  </div>
               </div>
            </section>
            <!-----------page_title-end-------------->
            <!-----------about--------------->
            <section class="about type_two pdt_100 pdb_100">
               <div class="container">
                  <div class="row">
                     <!---------row----------->
                     <div class="col-lg-6 col-md-12">
                        <!---------col----------->
                        <div class="image_box">
                           <img src="assets/image/resources/about-page-1-1.png" class="img-fluid" alt="img" />
                        </div>
                        <!---------col-end---------->
                     </div>
                     <div class="col-lg-6 col-md-12 d-flex">
                        <!---------col----------->
                        <div class="about_content">
                           <div class="heading tp_one">
                              <h1> Pandemic of Coronavirus Disease 2019-2020 </h1>
                           </div>
                           <p> The 2019–20 coronavirus pandemic is an ongoing pandemic of coronavirus disease 2019 (COVID-19), caused by severe acute respiratory syndrome coronavirus 2 (SARS-CoV-2). The outbreak was first identified in Wuhan, Hubei,
                              China, in December 2019. The World Health Organization (WHO) declared the outbreak to be a Public Health Emergency of International Concern on 30 January 2020 and recognized it as a pandemic on 11 March.
                           </p>
                           <p> As of 30 March 2020, more than 735,500 cases of COVID-19 have been reported in over 190 countries and territories, resulting in approximately 34,500 deaths. More than 156,000 people have since recovered.</p>
                        </div>
                        <!---------col-end---------->
                     </div>
                     <!----------row-end---------->
                  </div>
                  <div class="empty_space"></div>
                  <div class="row">
                     <!---------row----------->
                     <div class="col-lg-6 col-md-12 d-flex">
                        <!---------col----------->
                        <div class="about_content">
                           <div class="heading tp_one">
                              <h6>What are Coronavirus?</h6>
                              <h1> What is the COVID-19 virus?</h1>
                           </div>
                           <p>COVID-19 is a new strain of coronavirus that has not been previously identified in humans. It was first identified in Wuhan, Hubei Province, China, where it has caused a large and ongoing outbreak. It has since spread more
                              widely in China. Cases have since been identified in several other countries. The COVID-19 virus is closely related to a bat coronavirus. 
                           </p>
                           <h2> SARS-CoV-2 is closely related to the SARS-CoV</h2>
                           <p class="last"> It is thought to have a zoonotic origin. Genetic analysis has revealed that the coronavirus genetically clusters with the genus Betacoronavirus, in subgenus Sarbecovirus (lineage B) together with two bat-derived strains.
                              It is 96% identical at the whole genome level to other bat coronavirus samples (BatCov RaTG13). In February 2020, Chinese researchers found that there is only one amino acid difference in certain parts of the genome
                              sequences between the viruses from pangolins and those from humans, however, whole-genome comparison to date found at most 92% of genetic material shared between pangolin coronavirus and SARS-CoV-2, which is insufficient
                              to prove pangolins to be the intermediate host.
                           </p>
                        </div>
                        <!---------col-end---------->
                     </div>
                     <div class="col-lg-6 col-md-12">
                        <!---------col----------->
                        <div class="image_box">
                           <img src="assets/image/resources/protect-1-1.png" class="img-fluid" alt="img">
                        </div>
                        <!---------col-end---------->
                     </div>
                     <!----------row-end---------->
                  </div>
               </div>
            </section>
            <!-----------about-end-------------->
            <!-----------about--------------->
            <section class="about_covid_all type_one pdt_100 pdb_70">
               <div class="container">
                  <div class="row">
                     <!---------row----------->
                     <div class="col-lg-4 col-md-4 col-sm-6">
                        <!---------col----------->
                        <div class="icon_box type_one">
                           <div class="image_box">
                              <img src="assets/image/resources/icon-box-1-1.png" class="img-fluid" alt="img" />
                           </div>
                           <div class="content_box">
                              <h2><a href="#">Spreading</a></h2>
                              <p>The virus is mainly spread close contact and by respiratory droplets when people cough or sneeze. Respiratory droplets may be produced during breathing but the virus is not generally airborne. People may also catch
                                 COVID-19 by touching a contaminated surface and then their face. It is most contagious when people are symptomatic. 
                              </p>
                             
                           </div>
                        </div>
                        <!---------col-end---------->
                     </div>
                     <div class="col-lg-4 col-md-4 col-sm-6">
                        <!---------col----------->
                        <div class="icon_box type_one">
                           <div class="image_box">
                              <img src="assets/image/resources/icon-box-1-2.png" class="img-fluid" alt="img" />
                           </div>
                           <div class="content_box">
                              <h2><a href="#">Symptoms </a></h2>
                              <p> The time between exposure and symptom onset is typically around five days, but may range from 2 to 14 days. Common symptoms include fever, cough, and shortness of breath. Complications may include pneumonia and acute
                                 respiratory distress syndrome. There is no known vaccine or specific antiviral treatment. 
                              </p>
                             
                           </div>
                        </div>
                        <!---------col-end---------->
                     </div>
                     <div class="col-lg-4 col-md-4 col-sm-6">
                        <!---------col----------->
                        <div class="icon_box type_one">
                           <div class="image_box">
                              <img src="assets/image/resources/icon-box-1-3.png" class="img-fluid" alt="img" />
                           </div>
                           <div class="content_box">
                              <h2><a href="#">Treatment</a></h2>
                              <p>There is no known vaccine or specific antiviral treatment. Primary treatment is symptomatic and supportive therapy.Recommended preventive measures include hand washing, covering one's mouth when coughing, maintaining
                                 distance from other people, and monitoring and self-isolation for people who suspect they are infected.
                              </p>
                              
                           </div>
                        </div>
                        <!---------col-end---------->
                     </div>
                     <!----------row-end---------->
                  </div>
               </div>
            </section>
            <!-----------about-end-------------->
            <!-----------prevention--------------->
            <section class="prevention type_three pdt_100">
               <div class="map_bg"><img src="assets/image/resources/world-map-prevention-bg.png" class="img-fluid" alt="img" /></div>
               <div class="container">
                  <div class="row">
                     <!---------row----------->
                     <div class="col-lg-12 col-md-12 col-sm-6">
                        <!---------col----------->
                        <div class="prevention_content">
                           <div class="heading tp_one">
                              <h1>Disease Control and Prevention</h1>
                           </div>
                           <p> Efforts to prevent the virus spreading include travel restrictions, quarantines, curfews, workplace hazard controls, event postponements and cancellations, and facility closures. These include the quarantine of Hubei, national
                              or regional quarantines elsewhere in the world, curfew measures in China and South Korea, various border closures or incoming passenger restrictions, screening at airports and train stations, and outgoing passenger
                              travel bans.
                           </p>
                           <p>The pandemic has led to severe global socioeconomic disruption, the postponement or cancellation of sporting, religious, and cultural events, and widespread fears of supply shortages which have spurred panic buying. Schools
                              and universities have closed either on a nationwide or local basis in more than <span>160 countries</span>, affecting more than <span>1.5 billion students. </span>Misinformation and conspiracy theories about the virus
                              have spread online and there have been incidents of xenophobia and racism against Chinese, and other East and Southeast Asian people. As the pandemic spreads and hotspots form around the globe, such as those in Europe
                              and the United States, discrimination against people from these hotspots has also occurred.
                           </p>
                           <div class="image_box">
                              <img src="assets/image/resources/prevention-three-1.png" class="img-fluid" alt="img" />
                           </div>
                           <div class="theme_btn_outer">
                              <a href="#" class="theme_btn tp_one"><span class="linearicons-network"></span> Global Covid 19 Mapping outbreaks</a>
                           </div>
                        </div>
                        <!---------col-end---------->
                     </div>
                     <!----------row-end---------->
                  </div>
               </div>
            </section>
</template>